import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import * as styles from './HomeTopSection.module.scss';

class HomeTopSection extends Component {
  render() {
    const { onPlaystoreOpen } = this.props;

    return (
      <div className={styles.mainContainer}>
        <Jumbotron fluid className={styles.jumbotronContainer}>
          <div className={styles.mainText}>
            <p>The mobile app where you can <br /><b>Win  BIG</b> without paying</p>
          </div>
          <div className={styles.helperText}>
            <p>
              <b>Vuewin rewards you for viewing the hottest brands and events on your mobile phone!</b>
            </p>
          </div>
          <div className={styles.downloadsContainer}>
            <img
              src={require('../../assets/website_downloadbutton_android.png')}
              alt="download-google-play"
              className={styles.downloadButton}
              onClick={onPlaystoreOpen}
            />
            <img
              src={require('../../assets/website_downloadbutton_apple.png')}
              alt="download-apple-store"
              className={styles.downloadButton}
            />
          </div>
        </Jumbotron>
      </div>
    );
  }
}

export default HomeTopSection;
