import React from 'react';

import styles from './PrivacyPolicyPage.module.css';

const PrivacyPolicyPage = () => (
  <div className={styles.root}>
    <h1>VUEWIN PRIVACY POLICY</h1>
    <p>
      Vuewin’s privacy policy protects and values your personal information under the Data Privacy Act of 2012 and the laws of the Philippines. Personal information is defined as any information or data that can be used to distinguish, identify or contact you.
    </p>
    <p>
      At Vuewin, privacy means fair play and transparency. We would like to inform you of the data that we need and the reasons why we ask this information from you.
    </p>
    <p>
      As Vuewin is basically your personal billboard and content repository, we would like to improve your experience in our products and services.  As such, we would like to customize our service for you thus it is important that we collect, use, process and analyze your personal information when it is fair and required to do so.  As much as possible, we aggregate and anonymize this information so that you are not identified as an individual. When we aggregate, we group you in categories like male and female, age groups and such.  We anonymize by taking out information that may make it identifiable from your data.
    </p>
    <br />
    <h3>WHAT WE COLLECT</h3>
    <ul>
      <li>
        Identification – As Vuewin is your personal account, we use this information to identify and be sure that you are who you claim to be.  We need to make sure the points and rewards goes to the correct person.  This includes name (first, last), Date of Birth, e-mail address,  gender, device fingerprint and location. Not all are asked during registration but would be required in case of claiming such as the delivery address and the whole name. As stated above, the basic information is there to customize the ads that will be served to you so we can give you more relevant offers.
      </li>
      <li>
        Contact information – Information such as the mobile number and e-mail are our main ways to communicate with you regarding notifications, claiming instructions, promotions and other information that we need for you to know. This also allows us to answer queries and concerns regarding your account and make sure we are communicating with the account holder.  Such information can additionally include delivery address and fintech account so that we know where to send your rewards.  It can also include FB Messenger accounts for those who wish to communicate via social media.
      </li>
      <li>
        Survey Information – we will ask some questions from time to time to further enhance user experience and place you into groups who would receive special promotions.  Such information can be about your interest or additional profile such as location, educational attainment, travel interest and even brand preference.  These questions are aimed to help us make content more relevant to you in terms of ads and readings and eventually videos.  This will allow us to have a better relationship with you as a Kapanalo, create content that you like, focus our marketing efforts on messages which you will consider relevant and helpful.  Your approval or disapproval of the reading materials will help us avoid topics that are not interesting to you.
      </li>
      <li>
        Address – This information allows us to promote products and services that are within your general area.  Likewise, in case of delivery, this will serve as the delivery address to which we will have your rewards shipped.  In cases of fraud, an IP Address can be tracked with your consent.  Likewise, we are geo-locked in the Philippines.  Only valid PH addresses will be entertained.
      </li>
      <li>
        Device Information – This allows us to secure our network and improve our services and performance with regards to Vuewin.  We would know which items to optimize based on your operating system, operating system preference, device type and screen type.  We are currently based on a 16:9 screen aspect ratio.
      </li>
      <li>
        Purchase Information – this information just shows us what you are mostly interested in.
      </li>
      <li>
        Profile information- As mentioned, your e-mail and your mobile number are the 2 information which serve as our main way to contact you and your main identity until such time that you win rewards.  This has to be your own accounts and such passwords must be known to you.  Your birthdate will allow us to make sure you are the correct age to play and win in Vuewin as we are only for 18 years old and above.  Your name and gender will allow us to verify if you are indeed the account holder when compared to the rewards requirement such as birth certificate and government issued ID.  All this information also allow us to customized more relevant content for you.
      </li>
    </ul>
    <br />
    <h3>PURPOSES</h3>
    <b>WHY DO WE NEED THIS DATA?</b>
    <p>
      Essentially, we want to give you the best consumer experience with Vuewin so we customize according to your information.  We want to give you ads and reading materials that you might be interested in.  We value your time and we would like to serve to you what would be the more relevant for you so that you will continue to use Vuewin and so that you would find products and services that would suit you and make life a little better for you.
    </p>
    <p>Here are the following examples of what we do with your data:</p>
    <ol>
      <li>
        Advertise relevant products and services – Vuewin is a value exchange.  We serve you the brand ads which would be advantageous for you and them.  Given the unlimited ads out there, we would like to give you what will be more relevant for you.
      </li>
      <li>
        To keep you updated – We send you alerts and updates about our products and services based on the information you share with us. When you want to redeem items from us, we will be in touch with you via mobile phone or via e-mail.
      </li>
      <li>
        To safeguard our business – As we serve advertising and give out rewards,  we want to know that our advertisers are in touch with real people and not bots nor multiple account users.  We do not approve of underage users, multiple accounts, undocumented users, foreign users nor fraudulent users. We want to make sure that we are serving ads and content only to legitimate users.
      </li>
      <li>
        To determine authenticity of your rewards – We take note of your ad views, content reads, game play and such so that we know when you entered the game play and how you earned the chips for the game play.  This is for our mutual protection.  Likewise, we keep track of your data changes so that we know your account history so that we are both properly informed of our interactions.
      </li>
      <li>
        To safeguard your rewards – we will maintain your account to complete your rewards, maintain points and allow you to coordinate with us.  Make sure to safeguard your accounts since Vuewin is a privilege which can be revoked if abused.  A ban can be permanent so please deal with us with fairness and respect.  In some cases where you choose to log in thru your social media credentials, we will also have access to your social identifier.  Information of which is used similarly to the other information.
      </li>
      <li>
        To improve our product and services – We note how you get in touch with us and we take necessary steps to improve our response time by appropriating more staff or automating things if necessary.  We also do our best to protect your data by thru different measures that may be necessary.
      </li>
    </ol>
    <b>WHO DO WE SHARE YOUR DATA WITH?</b>
    <p>
      So that we can constantly improve your Vuewin experience, we share your data to our allied partners. We share your personal details to those who will help us provide you with a better user experience.  Once we share these data then we will post in the website their information so that you can contact them directly.  Typically these are our advertisers and publishers.  In case of rewards then we would likewise share your data when required with the authorities and the law enforcement.  These are mainly for tax purposes but it can also be used to verify your identity and in some cases, avoid fraud.
    </p>
    <p>
      Advertisers – we may have joint promotions and events with other companies.  If they were given the consent, these partners may also contact you directly.  If you do not wish to be contacted, please do not join in such promotions, programs or events.
    </p>
    <p>
      Future partners – All our data in Vuewin will be shared to prospective business partners in the event the Vuewin application is sold or integrated to another business entity.  Such information may be found in our website.
    </p>
    <b>WHAT IS OUR LEGAL BASIS FOR THE USE OF YOUR PERSONAL DETAILS?</b>
    <ul>
      <li>
        Your Consent – Once you sign up and participate in the game plays then you consent to us using your personal data in accordance with the Privacy Policy.
      </li>
      <li>
        Improvement of Service – we use your information to customize the content and ad materials to best suit your needs and to improve our efforts
      </li>
      <li>
        Compliance with the Laws- we will process and use your personal data to comply with legal obligations under laws which may apply.
      </li>
    </ul>
    <br />
    <h3>WHAT RIGHTS DOES USERS HAVE?</h3>
    <ul>
      <li>
        Access your personal details - You have the right to request access to your personal details. Your data may be found in your user profile. This also includes information about:
        <ul>
          <li>what personal details we have;</li>
          <li>what categories of data we use for filtering; like age, sex and in the future, location.</li>
          <li>what we do with your personal details which we have stated;</li>
          <li>who has access to your personal details (and where they are);</li>
          <li>where your personal details might be transferred to;</li>
          <li>
            how long we are keeping your personal details; we keep your data to prevent users from re-registering again in case of a ban; those who were not banned, we can delete all your personal data to allow you to re-register in the future.
          </li>
          <li>if you didn’t provide your personal details directly to us, how did we get them;</li>
          <li>your rights under applicable laws and the possibility to restrict processing; and</li>
          <li>if we use your personal details for any automated-decision making, and how we do it.</li>
        </ul>
        <p>If you wish to obtain a copy of the personal details we have on you, please log in to your account to make a request. If you wish to have information about the items identified above, please contact the us at <u>marketing@vuewin.com</u> so that we can process your request.</p>
      </li>
      <li>
        Delete your personal details - If you no longer want to interact with us, or you do not want us to have your personal details, you have the right to ask us to delete all your personal details from our systems except your name and birthdate so that no other user can use your data. However, please note that there might be legal obligations we need to comply with which compel us to decline your requests or to keep certain information.
      </li>
      <li>
        Correct your personal details - You have the right to make sure your personal details are accurate and complete. Kindly check your profile page and requests for an update if necessary.  Changing of data to remedy a reward transaction will invalidate the reward.  All changes must be done prior to winning and not upon winning.
      </li>
      <li>
        Restrict how we use and process your personal details - You also have the right to tell us to stop using your personal details in a particular way like you can have your interview material blurred or you can use an alias.  We will not share details with other players unless with consent.  To ensure transparency, some information may be shared.
      </li>
    </ul>
    <p>TO REACH US, PLEASE E-MAIL <u>marketing@vuewin.com</u> with the PRIVACY on the Subject Line. If you do not agree with the Vuewin Privacy Policy, please do not use Vuewin and please inform us to remove any details that you may have entered.</p>
  </div>

);

export default PrivacyPolicyPage;
