import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import * as styles from './HomeMidSection.module.scss';

class HomeTopSection extends Component {
    render() {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.titleContainer}>
                    <p>How it Works</p>
                </div>
                {!isMobile && (<div><br /><br /></div>)}
                <div className={styles.row}>
                  <div className={styles.workContainer}>
                    <div className={styles.column}>
                      <img
                          src={require('../../assets/website_howto_1.png')}
                          alt="website-how-to"
                          className={isMobile ? styles.mobileImage : styles.imageContainer}
                      />
                    </div>
                    <div className={isMobile ? styles.mobileColumn : styles.textColumn}>
                        <p className={styles.mainText}>
                            <b>Swipe</b>
                        </p>
                        <p className={styles.helperText}>
                          View ads to get Vuewin chips. The more ads you view, the more chips you get!
                        </p>
                    </div>
                  </div>
                  <div className={styles.workContainer}>
                    <div className={styles.column}>
                      <img
                          src={require('../../assets/website_howto_2.png')}
                          className={isMobile ? styles.mobileImage : styles.imageContainer}
                          alt="howti-2"
                      />
                    </div>
                    <div className={isMobile ? styles.mobileColumn : styles.textColumn}>
                        <p className={styles.mainText}>
                            <b>Play</b>
                        </p>
                        <p className={styles.helperText}>
                            Use your chips to play games to get more chips or premium gems.
                        </p>
                    </div>
                  </div>
                  <div className={styles.workContainer}>
                    <div className={styles.column}>
                        <img
                            src={require('../../assets/website_howto_3.png')}
                            className={isMobile ? styles.mobileImage : styles.imageContainer}
                            alt="howto-3"
                        />
                    </div>
                    <div className={isMobile ? styles.mobileColumn : styles.textColumn}>
                        <p className={styles.mainText}>
                            <b>Win</b>
                        </p>
                        <p className={styles.helperText}>
                            Win big from playing games, and exchange your chips and gems for exciting rewards!
                        </p>
                    </div>
                  </div>
              </div>
            </div>
        );
    }
}

export default HomeTopSection;
