import React, { Component } from 'react';
import Modal from 'react-modal';

import * as styles from './Home.module.scss';
import NavHeader from './NavHeader';
import HomeTopSection from './HomeTopSection';
import HomeMidSection from './HomeMidSection';
import RewardsSection from './RewardsSection';
import Footer from './Footer';
import BetaModal from './BetaModal/BetaModal';


const customModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    position: 'absolute',
    height: '300px',
    width: '800px',
    margin: '0 auto',
  }
};


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBetaModalOpen: false,
    };
  }

  openPlaystore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.vuewin&hl=en');
  };

  closeModal = () => {
    this.setState({ isBetaModalOpen: false });
  };

  render() {
    const {
      isBetaModalOpen,
    } = this.state;

    return (
      <div className={styles.mainApp}>
        <NavHeader
          onPlaystoreOpen={this.openPlaystore}
          onPrivacyPolicyOpen={this.openPrivacyPolicyPage}
        />
        <Modal
          isOpen={isBetaModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Vuewin is undergoing Beta Testing"
          style={customModalStyles}
        >
          <BetaModal onClose={this.closeModal} />
        </Modal>
        <div className={styles.mainApp}>
          <HomeTopSection onPlaystoreOpen={this.openPlaystore} />
          <HomeMidSection />
          <RewardsSection onPlaystoreOpen={this.openPlaystore} />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Home;
