import React from 'react';

import styles from './FaqPage.module.scss';

const READ_WALL_ICON = require('../../assets/3x_menu_read_active.png');
const AD_WALL_ICON = require('../../assets/3x_menu_adwall_active.png');
const REWARDS_WALL_ICON = require('../../assets/3x_menu_rewards_active.png');
const PLAY_WALL_ICON = require('../../assets/3x_menu_play_active.png');
const ACCOUNT_WALL_ICON = require('../../assets/3x_menu_account_active.png');


const FaqPage = () => (
  <div className={styles.root}>
    <h1>VUEWIN FREQUENTLY ASKED QUESTIONS</h1>
    <h3>ELIGIBILITY - WHO CAN JOIN</h3>
    <ol>
      <li>
        <b>What is the difference between Vuewin 1 and 2 for registration?</b> Vuewin 2 allows users to register using only mobile number and password. Likewise there are no more star levels in Vuewin 2, no more need to have a bank account nor a passport with stamp in the Vuewin 2.
        <br />
        Vuewin however remains to be open only to Filipinos, 18 years old and above and are residing in the Philippines. Filipinos residing or unable to claim locally are still not allowed. Likewise, no foreigners are also allowed to win in the games.
        <br />
        The basic requirement has been simplified but the following information are needed for game play: First Name, e-mail address, birth date, sex and for claiming, a government issued ID enumerated below and a PSA or NSO Birth Certificate.
      </li>
      <li>
        <b>Why do I need to show my PSA or NSO Birth Certificate?</b> The Birth Certificate will allow us to verify if you are the registered user on the application and that you have given the correct birth date and first name on your Vuewin account. The valid government ID also is your supporting document for to show that you are indeed the one on the birth certificate and not just someone with the same birthdate.
      </li>
      <li>
        <b>I am a foreigner married to a Philippine citizen and I am residing in the Philippines. Can I play and win in Vuewin?</b> No. The Vuewin Games are open only to Filipino legal residents of the Philippines with ages 18 years and above. Void in areas outside the Philippines. Employees of Vuewin Inc. and its app development team, their relatives up to the first degree of consanguinity for affinity are disqualified from joining the games. This is stated in our Rule Number 1 concerning Eligibility.
      </li>
      <li>
        <b>I have a dual-SIM phone and I am sharing with my sister? We have different accounts since using the same phone. Is this ok?</b> No. Only one account per device even if you are two separate users. This is stated in Rule Number 5b.
      </li>
    </ol>
    <h3>WINNING AND CLAIMING – How do I win? What do I win?</h3>
    <ol>
      <li>
        <b>Did I win? How to find out?</b> Tap the screen to see the menu and Press the Game Icon. Press the Right Top Portion which says RESULTS. You will now be shown the latest result. For other dates, you need to Press the downward arrow to show the date menu. Choose the Date of the draw you wish to see. <span className={styles.redText}> Go to the Menu and go to NOTIFICATIONS in RESULTS page of the Vuewin App. When you see the label ‘YOU WON’, tap on it to show all your entries for that game then tap the ‘Claim’ button to initiate the claim process. Consolation prize in the form of chips are credited to your account instantly, however, for the jackpot prize, you will receive an email message with instructions to send game entry details to prizes@vuewin.com. This is in Rule Number 11. </span>
      </li>
      <li>
        <b>I have 4 matches in the 4D? How come I only won regular chips?</b> You have won 4 matches but the numbers are not in order so this makes you eligible for a consolation prize and not a Jackpot prize. The jackpot combination follows the exact order on the number chosen in the equivalent PCSO draw in which the number count of the matches will start from the rightmost digit. This is in Rule Number 8b ii.
        <br />
        <br />
        (Example: Winning Combination is 1-2-3-4, your choice was 1-2-4-3 or 4-3-2-1, you get 4 matches BUT not in the correct order so you win a consolation prize only.)
      </li>
    </ol>
    <h3>ICONS</h3>
    <ol>
      <li>
        <b>What do these icons mean?</b>
        <table className={styles.table}>
          <tr>
            <th>Icon</th>
            <th>Wall</th>
            <th>Contents</th>
          </tr>
          <tr>
            <td>
              <img alt="read" className={styles.icon} src={READ_WALL_ICON} />
            </td>
            <td>Read Page</td>
            <td>Read the News and Enjoy the Fun Articles that you can give you additional ads.</td>
          </tr>
          <tr>
            <td>
              <img alt="ad" className={styles.icon} src={AD_WALL_ICON} />
            </td>
            <td>Ads Page</td>
            <td>View the ads, surveys, coupons, hi-lo games</td>
          </tr>
          <tr>
            <td>
              <img alt="rewards" className={styles.icon} src={REWARDS_WALL_ICON} />
            </td>
            <td>Rewards Page</td>
            <td>Regular Chips page and Gems page rewards can be viewed here. Pending claims containing your claims that would still need your action or coupons that you can still redeem are placed here.</td>
          </tr>
          <tr>
            <td>
              <img alt="play" className={styles.icon} src={PLAY_WALL_ICON} />
            </td>
            <td>Games Page</td>
            <td>The 3 games can be found here- 2N, 4D and 6/49 as well as the Results in the upper right corner. </td>
          </tr>
          <tr>
            <td>
              <img alt="account" className={styles.icon} src={ACCOUNT_WALL_ICON} />
            </td>
            <td>Profile Page</td>
            <td>Your information page where you can see your mobile number referral code, chips and gems count, different missions that can earn you chips, activity log and the help and support link. You can also edit certain profiles you need to update here like mobile number and e-mail addresses.</td>
          </tr>
        </table>
      </li>
    </ol>
    <h3>CHIPS</h3>
    <ol>
      <li>
        <b>What are the chips for? What can we do we them?</b>
        <br />
        There are 2 kinds of chips – Regular (chips) and Premium (gems).
        <br />
        <br />
        <u><b>The Regular Chips</b></u>
        <br />
        The Regular Chips are earned in viewing the ads or prizes in the Hi-Lo Game. Likewise, it can be from the balato winning or referral bonuses. There is also the new missions which also gives out the regular chips.
        <br />
        The regular chips can be used playing the Hi-Lo Game.
        <br />
        These regular chips that you accumulate thru viewing the ads or winning the Hi-Lo Game or as consolation prizes can be used to redeem regular rewards such as Vuewin tickets, Load, Garena Shells and Steam wallet for the gamers or the Vuewin shirts. Check the Regular Reward inventory in the Rewards Section of the App.
        <br />
        Another new feature is that you can now use your regular chips to play the Vuewin Lottery Game. You can now choose if you want to go for exchange to smaller prizes or use the chips to aim for the bigger jackpots in the Vuewin Lottery Game.
        <br />
        <br />
        <u><b>The Premium Chips or Gems</b></u>
        <br />
        Gems are won thru winning the jackpots or a second prize in the 6/49 games. In addition to this, the gem can be won if your referral wins a jackpot. Although your gem will show in your Premium Rewards, they can only be exchanged after verification. Getting the gems does not automatically allow you to exchange for the prizes since it still needs to be verified as per Rule Number 11 concerning Eligibility and Claiming.
      </li>
      <li>
        <b>How to convert my regular chips to rewards?</b> If you have collected at least 3,000 regular chips, you may exchange these for P30 prepaid load. To do this, press reward icon to regular rewards and tap the P 30 Load and then on the Php 30 Load screen, Tap Get for 3,000 regular chips. Once you confirm, there will be an instruction on which number the load will be sent. If no number is provided, your Vuewin registered Vuewin number will receive the load.
        <br />
        Only prepaid numbers can receive the load. Likewise there are other rewards with their corresponding chip values to be redeemed.
      </li>
      <li>
        <b>I converted my 3,000 chips to load but how come I have not received my load?</b> It takes about 24 to 48 hours to send the load since your account needs to be verified. If there are no issues on your account, then the load should be credited to your number. In case you are still unable to collect the load, please send an e-mail to <u>prizes@vuewin.com</u>.
        <br />
        Some possible reasons for delays are loads central is down, error in number entry and I some cases flagged account due to multiple accounts in Vuewin and unanswered e-mail inquiries for follow up by our prizes@vuewin.com for additional requirements to clear the claim.
        <br />
        Bear in mind to be patient as this is a free app which you have not paid for. Our team however deserves some gratitude and respect and we reserve the right to ban rude and threatening language. We require courtesy from our users while we sort out any lapses. Make sure to direct your concern to prizes@vuewin.com since this contacts those concerned directly. Posting on Facebook, Playstore, Appstore and other social media may not be answered immediately as this does not show your e-mail account details. With the hundreds of inquiries posted on the Social Media, your message may be overlooked as well.
      </li>
      <li>
        <b>I have enough regular chips to redeem my reward which says unlimited but it says that we can only accommodate 30 loads per day? What can I do? </b> The Php 30 Load is Unlimited but only 30 transactions is done per day at the moment. The best time of the day to get your load redeemed would be in the morning when the 30 allocations are released.
      </li>
      <li>
        <b>Can I save my gems to redeem higher prizes?</b> Yes BUT gems have expiration days for conversion into prizes so if your verified gems are ready for redemption, you only have 60 days from the date of the winning entry. You are not assured that your all your gems will be valid within the same time frame. As stated in Rules 11c, the 60 days is based on the winning date and NOT on the gems claiming.
      </li>
      <li>
        <b>I claimed my gems and have them in my account, I was redeeming within 60 days, how come I could not still redeem my prizes?</b> Matching the numbers allows you to claim the gems. Redemption of the gems require the birth certificate, a government issued ID and the printed e-mail. If you do not have any of those beyond the 60 days then the gems will be invalidated and removed from your account.
      </li>
      <li>
        <b>Why did I lose my gems?</b> Gems are lost if you are unable to finish the redemption within the 60 days period from the date of the winning entry. If you won the July 1 draw, you need to have redeemed or converted your gems into rewards with all the necessary documents submitted and verified by August 30th which is the 60th day from the time you won the gems. Failure to do so will make you lose your gems. The only thing that can go beyond the 60th day is your schedule your personal claim of the prize in the Vuewin office.
      </li>
      <li>
        <b>I won 10,000 gems and it has been verified, can I choose anything from the rewards?</b> If there are stocks available for your choices and can be covered by your remaining gem stones after tax then it is possible. However there are no change to be given so best to exchange for the exact value. Likewise the 60 day rule and the documents are required.
      </li>
      <li>
        <b>Are my chips taxable? If I win 100,000 gems, do I need to pay for anything?</b> Just like anything, taxes NEEDS to be paid. For the 4D jackpot, we have already taken care of the taxes but for the 6/49 jackpots, it will be subject to 20% taxes. Remember that all prizes beyond P 10,000 are taxable by 20%.
      </li>
      <li>
        <b>How can I use claim my prize if I am not able to pay for the 20% taxes? If I get the 100,000 gems, can I just get 80,000 gems and leave the 20,00 gems as the tax payment?</b> Yes, you can do that and we would be the one to remit the appropriate taxes to the government. We would need your Tax Identification Number to withhold the taxes and issue a certification that the tax has been paid.
      </li>
    </ol>
    <h3>CHIPS</h3>
    <ol>
      <li>
        <b>I accidentally registered 2 accounts. How do I deactivate my account?</b> Multiple account is not eligible to win as stated in Rule Number 5 in Vuewin. Multiple account is actually grounds for disqualification. To deactivate accounts, user must identify the email addresses of the accounts that need to be deactivated then send an email request message to support@vuewin.com. A reply message will be received from support@vuewin.com for coordination and appropriate action.
      </li>
      <li>
        <b>Do I get notification that my account is disqualified?</b> You would only know that your account is disqualified due to multiple accounts when you are claiming rewards. If you would like to know if someone has used your e-mail account, mobile number or have any grounds for disqualification, please e-mail support so that erroneous registration can be deactivated. If you believe that you had an error in your previous registration like wrong e-mail or any other details, please e-mail support@vuewin.com immediately.
        <br />
        Even if the duplicate account has been dormant/unused due to an error, your account will still be disqualified. Its better to have your account verified now than at time when you can no longer claim your prize.
      </li>
      <li>
        <b>I forgot my password. How should I recover or set a new password?</b> <span className={styles.redText}>You may re-set your Vuewin account password by tapping the ‘?’ in the login page. An email message from Vuewin will be sent to your Vuewin-registered email address containing the password reset link. This necessitates that your registered e-mail is active. If you have forgotten your e-mail password, have the e-mail provider reset your password as we will only send the link to your Vuewin registered e-mail address and not to a new e-mail address. </span>
      </li>
      <li>
        <b>My phone got stolen, how can I change my account?</b> Yes, you can report to Vuewin using your verified Vuewin registered e-mail address. Send an e-mail to support@vuewin.com so that we know that you are changing your number connected to the game play. You can change your number in the profile section of the app. You can also notify us that you will be changing your e-mail the same way and then change via the app profile edit page.
      </li>
      <li>
        <b>I made a mistake in my registration so I made a new account. I do not use the account with the error. Will this be considered 2 accounts? </b> <span className={styles.redText}> Yes, your account will be flagged since you will have 2 accounts probably with all correct details and another one with a wrong e-mail, a wrong birthday or any other wrong info. This is a common mistake so please e-mail support@vuewin.com and they can deactivate the wrong account. <br /> To prevent identity theft, it is best that you give the first name that appears in your birth certificate so that we can be assured it is you who would be claiming the prize when you win. There are instances that single users are flagged due to similar ISPs </span>
      </li>
      <li>
        <b>When I won, I was given a flagged account. I only have one account. Why can’t I claim my prize?</b> There could have been a mistake during registration due to poor internet connection or some other reason. Such instances result in duplicate accounts so you may not be able to claim your prize because of this. Please e-mail prizes@vuewin.com and support@vuewin.com to explain your concern. Do not start ranting on Facebook and the Playstore since using foul or derogatory language can mean your ban from the app usage.
        <br />
        It is also possible that you have a similar ISP and a similar birthdate with some other user so its is actually better to give such information like your first name as stated in your birth certificate so we can cross check with the other duplicate account. If its verified that our system wrongly flagged your account then we can correct it.
      </li>
    </ol>
    <h3>PLAYING VUEWIN</h3>
    <ol>
      <li>
        <b>How do I play Vuewin?</b> You would need to give the correct information during registration, we require the mobile number, e-mail address and your birthdate. If any of these would be incorrect, you can be disqualified from claiming your prize. Kindly see Rules Number 1, 4 and 5.
        <br />
        Every ten (10) ads you view in Vuewin, earns you a ticket that you can play in any of the number game namely: 2N, 4D and 6/49 games. When your entry matches exactly the winning combination (and in the exact order for 4D), your jackpot prizes are the following:
        <br />
        <br />
        2N – XX Gems
        <br />
        4D – XXX Gems
        <br />
        6/49 – XXXX Gems
        <br />
        <br />
        If you have been verified a winner, you can use the bronze chips to exchange for load and other items from the reward tab in the app. The Silver chips can be exchanged for cash and other items like phones. The Gold Chips can be exchanged for cash, mobile phones automobiles or other items.
        <br />
        <br />
        Daily use of the Vuewin app will help you discover other features and how you can increase your chances of winning.
        <br />
        <br />
        There is also the Hi-Lo game where you can win the regular chips.
      </li>
      <li>
        <b>How do I win in Vuewin?</b> There are several ways to win in Vuewin. First, you already win a regular chip every time you view the ads. You can exchange the regular chips for prizes enumerated in the rewards section of the app.
        <br />
        Second, you can also use the regular chips to win more regular chips via the Hi-Lo Game. The Hi-Lo game is simply guessing if the next number is going to be lower or higher than the system generated number. The odds of winning determines the regular chips to be won. Check the Potential Reward and the Odds that are shown next to your choice. Press the letter ï”on the top right portion of the Hi-Lo screen to learn more about the game.
        <br />
        Third, you can also win by playing the tickets that you earn from viewing the ads. You can choose from the different games likes 2N, 4D and 6/49. By matching the winning combination of the numbers drawn, you can win the premium chips. The rules can be found in the Vuewin Rules Numbers 7 and 9.
        <br />
        Fourth, there are also consolation prizes when you match some of the numbers or match all the numbers in the 4D but not in the exact order. Prizes range from regular chips to premium chips. This is stated in Vuewin Rule Number 3.
        <br />
        Lastly, we you don’t have to win the jackpot to get the premium chips. If you referred your friend and he/she is the verified winner of the jackpot, you can also win 10% of what your friend have won. If your account both your accounts are active and verified then you are entitled to win 10% of their winnings. So the more friends you have that are viewing the ads with you, the more chances of winning. Have them use your referral code upon registration otherwise you can not input the referral code after they have fully installed the app.
      </li>
      <li>
        <b> What is the feature of winning the “balato” from those that used my referral code? Do they lose out on their winnings?</b> Vuewin wants to reward as much people as possible for taking time to pay attention to the ads and also for patronizing our advertisers. As such we now have a “balato” system. If you and your friend are active verified users of the app, it means that those who used your referral code will be linked to your account. In addition, those who are active and verified users that used your friend’s referral code will be linked to your account as well. You can see this in the Referrals part of the app. You can see your first circle or those who registered using your referral code and your second circle or those that used your friend’s referral code.
        <br />
        If you are an active and verified user and if someone that is a verified user in your first circle wins, then you automatically get 10% of his/her winnings.
        <br />
        If you are an active and verified user and if someone that is a verified user in your second circle wins, then you automatically get 5% of his/her winnings.
      </li>
      <li>
        <b>Where do I find my referral code? How do I use it?</b> Your referral code is found in the referrals page in the app. It is in the middle of the circle which says your code. Your referral code can be used as many times as possible and you and your referral wins then you can a “balato” from their winning. If your referrals or your account is flagged however, you will not be winning anything so make sure their accounts won’t be flagged. No multiple accounts nor erroneous information. It is useless to make fake accounts since it will result in your disqualification from Vuewin. You will not be able to register again or your account will already be flagged. Vuewin is a privilege and not a right.
        <br />
        Also the referral code can only be used during registration, if it was not used during the initial registration then they won’t be able to use your referral code after using the Vuewin app.
      </li>
    </ol>
    <h3>CLAIMING YOUR PRIZE</h3>
    <ol>
      <li>
        <b>How do I claim my prize?</b> Claiming your prize necessitates that we verify your account. You will have to submit different documents based on the documents that you have mentioned that you have during the registration. This is discussed in Vuewin Rules Number 11. The Rules are found in the App under Settings : General : Vuewin Rules
        <br />
        Failure to present any of the documents that you have stated during the registration invalidates your chances of winning for that draw and can possibly result in disqualification and ban from the use of Vuewin.
      </li>
      <li>
        <b>I don’t have an NSO Certified Birth Certificate, how can I claim?</b> Unfortunately, this is necessary to claim any jackpot prize. You will not be able to claim anything if you do not have this. Make sure you have one in your possession. You can also request online via nsohelpline.ph/order-now
      </li>
      <li>
        <b>Do we need to finish the missions?</b> We created missions to make registration easier and to reward you with prizes in completing different tasks. In this case, missions will be best done immediately. Finished missions will no longer be shown in your mission task but may be seen in the activity logs. Completing your missions is necessary to make the most out of the Vuewin 2.0 app. Some missions are required for claims.
      </li>
    </ol>
    <br />
    <p>
    For the current version of the Vuewin App, regular chips are earned from daily viewing of Ads and as consolation prize from 4D and 6/49 games. You may use these chips to play the Hi-Lo game and exchange for premium reward items. At present, rewards items include: 1 Ticket for 200 regular chips; 3 Tickets for 550 regular chips; and P30 prepaid load for 3000 regular chips.
    <br />
    <br />
    <i>
    Para po sa kasalukuyang version ng Vuewin App (1.1.6), ang regular chips ay makukuha sa araw-araw na pag-view ng mga Ads at nakukuha bilang consolation prize ng 4D at 6/49 games. Magagamit po ninyo ang mga regular chips sa paglalaro ng Hi-Lo game at pwedeng ipagpalit sa mga premium reward items. Sa ngayon ang mga reward items ay ang mga sumusunod: 1 Ticket para sa 200 na regular chips; 3 Tickets para sa 550 na regular chips; at P30 prepaid load para sa 3,000 na regular chips.
    </i>
    </p>
    <ol>
      <li>
        <b>How to use this app?</b> Congratulations for having installed and registered with Vuewin!
        <br />
        <br />
        For details, please read Vuewin Rules at SETTINGS or at http://vuewin.com/rules/.
      </li>
      <li>
        <b>How to make changes to my profile?</b> You may email support@vuewin.com to request for changes in your profile.
      </li>
      <li>
        <b>How to earn tickets and chips?</b> Tickets and chips are earned from viewing ads at the ‘View Ads’ panel of the Vuewin app.
        <br />
        <br />
        Every ten (10) ads you view in Vuewin, earns you a ticket that you can play in any of the number game namely: 2N, 4D and 6/49 games.
        <br />
        <br />
        Daily viewing of ads also earns you chips that you can use to play the Hi-Lo game or exchange for rewards (tickets, P30 load, Vuewin T-shirt, more items later).
      </li>
    </ol>
    </div>
);

export default FaqPage;
