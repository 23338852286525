import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './global/components/Home';
import PrivacyPolicyPage from './global/components/PrivacyPolicyPage/PrivacyPolicyPage';
import FaqPage from './global/components/FaqPage/FaqPage';
import PreregistrationPage from './global/components/Preregistration/Preregistration';


class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route path="/docs/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/docs/preregistration" component={PreregistrationPage} />
        <Route path="/docs/faq" component={FaqPage} />
      </Router>
    );
  }
}

export default App;
