import React, { Component } from 'react';
import * as styles from './Footer.module.scss';

class Footer extends Component {
  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.rightTextContainer}>
          <p><b>VUEWIN</b></p>
          <p>All rights reserved, 2019.</p>
        </div>
      </div>
    );
  }
}

export default Footer;
