import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Jumbotron } from 'react-bootstrap';
import * as styles from './RewardsSection.module.scss';

class RewardsSection extends Component {
  render() {
    const { onPlaystoreOpen } = this.props;

    return (
      <div className={styles.mainContainer}>
        <div className={isMobile ? styles.mobileCarImagecontainer : styles.carImageContainer}>
          <img
            src={require('../../assets/website_cars.png')}
            alt="carimage"
          />
        </div>
        <div>
          <Jumbotron fluid className={styles.jumbotronContainer}>
            <div className={styles.rewardsText}>
              <p>Rewards</p>
            </div>
            {isMobile && (
              <div><br/><br/></div>
            )}
            <div className={styles.helperTextSection}>
              <div className={styles.textHolder}>
                <p align="left">
                  Big rewards are up for grabs in exchange for your chips and premium gems. Drive a brand new car, take home some cash, or get awesome gadgets using your premium gems.
                </p>
              </div>
              <div className={styles.textHolder}>
                <p align="left">
                  Cellphone loads and other merchandise are also available in exchange for regular chips. Lots of other rewards are also given during special events.
                </p>
              </div>
            </div>
          </Jumbotron>
        </div>
        <div>
          <Jumbotron fluid className={styles.lowerHalf}>
            <div className={styles.vuewinLogoContainer}>
              <img
                src={require('../../assets/website_logo_bot_without_beta.png')}
                alt="download-google-play"
                className={styles.downloadButton}
              />
            </div>
            <div className={styles.downloadText}>
              <p>Download the app today & you might be the next winner!</p>
            </div>
            <div className={styles.linksContainer}>
              <img
                src={require('../../assets/website_downloadbutton_android.png')}
                alt="download-google-play"
                className={styles.downloadButton}
                onClick={onPlaystoreOpen}
              />
              <img
                src={require('../../assets/website_downloadbutton_apple.png')}
                alt="download-apple-store"
                className={styles.downloadButton}
                onClick={onPlaystoreOpen}
              />
            </div>
            <div className={styles.helpText}>
              <p>Feel free to send us a message at <b>support@vuewin.com</b></p>
            </div>
            <div className={styles.helpText}>
              <p>Follow us to get the latest updates:</p>
            </div>
            <div className={styles.followContainer}>
              <a href="https://www.facebook.com/Vuewin/">
                <img
                  src={require('../../assets/website_followus_fb_inactive.png')}
                  alt="follow-fb"
                  className={styles.followButton}
                />
              </a>
              <a href="https://twitter.com/vuewinapp?lang=en">
                <img
                  src={require('../../assets/website_followus_twitter_inactive.png')}
                  alt="follow-twitter"
                  className={styles.followButton}
                />
              </a>
            </div>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

export default RewardsSection;
