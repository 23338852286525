import React from 'react';

import styles from './BetaModal.module.css';

const BetaModal = ({ onClose }) => (
  <div className={styles.root}>
    <button onClick={onClose} className={styles["close-button"]}>Close</button>
    <h1>Vuewin is currently undergoing beta-testing!</h1>
    <p>
      As of now, the mobile app is only available for access by our beta testers.
      We're working as hard as we can to deliver the app to you! Check back soon for more updates.
      In the meantime, follow us on our <a href="https://www.facebook.com/Vuewin/">facebook page</a> for news, announcements, and promos.
    </p>
  </div>

);

export default BetaModal;
