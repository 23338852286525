import React from 'react';

import styles from './Preregistration.module.scss';
import Footer from '../Footer';

const MAIN_SCREEN = require('../../assets/page1.jpg');
const EARLY_ACCESS = require('../../assets/page2.jpg');
const REGISTRATION_PAGE = require('../../assets/page3.jpg');


const PreregistrationPage = () => (
  <div>
    <div className={styles.root}>
      <h2>How to register after being approved for preregistration</h2>
      <br />
      <p>Kapanalo! Hindi ka ba maka log in matapos mong ma approve sa preregistration?</p>
      <p>Sundan lang ang mga sumusunod:</p>
      <ol>
        <li>
          Buksan ang <b>Vuewin App</b> at pumunta sa <i>Create Account</i> page.
          <br />
          <br />
          <img alt="read" className={styles.screenshot} src={MAIN_SCREEN} />
        </li>
        <br />
        <li>
          Pagdating sa <i>Create Account</i> page, hanapin ang icon sa upper right corner ng screen mo, upang makapunta sa <i>Early Access</i> page.
          <br />
          <br />
          <img alt="read" className={styles.screenshot} src={EARLY_ACCESS} />
          <br />
          <br />
        </li>
        <br />
        <br />
        <li>
          Pagdating sa <i>Early Access</i> page, maaari mo nang i-input ang iyong mobile number at password at magregister.
          <br />
          <br />
          <img alt="read" className={styles.screenshot} src={REGISTRATION_PAGE} />
          <p>Kung meron kayong referral code, maari niyo siyang gamitin matapos niyo mag register.</p>
          <br />
          <br />
        </li>
        <li>Maari niyo nang gamitin ang <b>Vuewin App</b>!</li>
      </ol>

      <b>Kung may mga karagdagang tanong, maari niyong i-contact ang <i>support@vuewin.com</i></b>
      <br/>
      <br/>
    </div>
    <Footer />
  </div>
);

export default PreregistrationPage;
