import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as styles from './NavHeader.module.scss';
import Sidebar from './Sidebar';

const MOBILE_NAVBAR_LOGO = require('../../assets/website_navbar_logo_no_text.png')

class NavHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSideBar: false,
    };
  }

  toggleSideBar = () => {
    const { showSideBar } = this.state;

    this.setState({
      showSideBar: !showSideBar,
    });
  }

  render() {
    const { onPlaystoreOpen } = this.props;
    const { showSideBar } = this.state;

    return (
      <header className={styles.navBar}>
        <nav>
          <Sidebar toggleSideBar={this.toggleSideBar} showSideBar={showSideBar} />
          <div className={styles.logoSidebarContainer}>
            <div onClick={this.toggleSideBar} className={styles.sidebarButton}>
              <div className={styles.sideBarButtonRow} />
              <div className={styles.sideBarButtonRow} />
              <div className={styles.sideBarButtonRow} />
            </div>
            <div href="#home">
              <img
                src={MOBILE_NAVBAR_LOGO}
                alt="vuewin-logo"
                className={styles.logoNavBar}
              />
            </div>
          </div>
          <div className={styles.navBarLinksContainer}>
            {!isMobile && (
              <Link to="/docs/privacy-policy/">
                <Button
                  className={styles.navButton}
                >
                  <p>PRIVACY POLICY</p>
                </Button>
              </Link>
            )}
            {!isMobile && (
              <Link to="/docs/faq/">
                <Button className={styles.navButton}>
                  <p>FAQ</p>
                </Button>
              </Link>
            )}
            <img
              src={require('../../assets/website_navbar_getthisapp.png')}
              alt="get-the-app-icon"
              className={styles.getThisAppIcon}
              onClick={onPlaystoreOpen}
            />
          </div>
        </nav>
      </header>
    );
  }
}

export default NavHeader;
