import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as styles from './NavHeader.module.scss';

const CLOSE_ICON = require('../../assets/content_clear_formfield.png')

const Sidebar = ({ showSideBar, toggleSideBar }) => {
  if (showSideBar) {
    return (
      <div className={styles.sidebar}>
        <div onClick={toggleSideBar} className={styles.closeSidebarContainer}>
          <img
            alt="close-icon"
            src={CLOSE_ICON}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.documentList}>
          <Link to="/docs/privacy-policy/">
            <Button
              className={styles.navButton}
            >
              <p>PRIVACY POLICY</p>
            </Button>
          </Link>
          <Link to="/docs/faq/">
            <Button className={styles.navButton}>
              <p>FAQ</p>
            </Button>
          </Link>
        </div>
      </div>
    );
  }
  return null;
}

export default Sidebar;
